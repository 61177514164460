import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ResetPassService {
  public baseURL = environment.config.apiKey;
  constructor(private http: HttpClient) {}

  resetPass(data: any): Observable<any> {
    const headers = {
      // headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.post(this.baseURL + '/auth/reset_password' , data, headers);
  }
}
@Injectable()
export class ResendService {
  public baseURL = environment.config.apiKey;
  constructor(private http: HttpClient) {}

  resendOtp(data: any): Observable<any> {
    const headers = {
      // headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.post(this.baseURL + '/auth/resend_otp' , data, headers);
  }
}
